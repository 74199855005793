import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Header from "../components/Header";
import Footer from "../components/Footer";

const GlobalStyle = createGlobalStyle`
  body {
    background: #111;
    font-family: sans-serif;
  }
`;

const PageWrapper = styled.div`
  color: #fff;
  min-height: 100vh;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .Footer {
    padding-top: unset;
  }
`;

const ContentWrapper = styled.section`
  margin: 0 auto;
  max-width: 600px;
  padding: 1rem;
  flex: 1;

  p {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
`;

export const InfoPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <PageWrapper>
      <Header forceRelative />
      <ContentWrapper>
        <GlobalStyle />
        <h1>{title}</h1>
        <PageContent className="content" content={content} />
      </ContentWrapper>
      <Footer />
    </PageWrapper>
  );
};

InfoPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const InfoPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <InfoPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

InfoPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InfoPage;

export const infoPageQuery = graphql`
  query InfoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
